<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">搜索结果</div>
      <div class="topRight"></div>
    </div>
    <div class="contentBox" v-for="item in schoolList" :key="item.id">
      <div class="contentTop">
        <div class="hear"><img :src="item.logo" alt /></div>
        <div class="schoolBox">
          <div>{{ item.name }}</div>
          <div>{{ item.title }}</div>
        </div>
        <div class="zkBtn" @click="goDetail(item)">
          <img src="../../image/zk.png" alt />
        </div>
      </div>
      <div class="contentBot">
        <div class="botBox" v-for="i in item.infos" :key="i.id">
          <div><span v-if="i.timeShow == '刚刚'">新</span> {{ i.title }}</div>
          <div @click="detailEvent(i)">查看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { schoolPage } from "../../api/lookAt";
export default {
  data() {
    return {
      schoolObj: {
        pageNum: 1,
        pageSize: 10,
        provinceId: "", //省级id
        cityId: "", //市级id
        period: "", //学段id
        schoolName: this.$route.query.value || "", //名校名称
      },
      schoolList: [], //名校列表
    };
  },
  mounted() {
    schoolPage(this.schoolObj).then((res) => {
      console.log(res, "名校2222");
      this.schoolList = res.data.data.items;
      this.loading = false;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 名校详情
    goDetail(item) {
      console.log(item);
      this.$router.push({ path: "/schoolDetail", query: { item: item } });
      this.$store.commit("active", 0);
      this.$store.commit("schoolItem", item);
    },
    // 跳转资讯详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { item: item } });
      this.$store.commit("item", item);
    },
  },
};
</script>

<style scoped>
.setBox {
  min-height: 100vh;
  background: #f6f8fa;
}
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 18px; */
  height: 88px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.contentBox {
  width: 686px;
  border-radius: 16px 16px 16px 16px;
  background: #fff;
  margin-left: 32px;
  margin-top: 32px;

  overflow: hidden;
}
.contentTop {
  display: flex;
  margin-top: 32px;
}
.hear img {
  width: 96px;
  height: 96px;
  border-radius: 12px 12px 12px 12px;
  margin-left: 32px;
}
.schoolBox {
  width: 386px;
  margin-left: 20px;
}
.schoolBox div:nth-child(1) {
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.schoolBox div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zkBtn img {
  width: 120px;
  height: 56px;
  margin-top: 16px;
}
.contentBot {
  width: 622px;
  /* height: 144px; */
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  margin-left: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  margin-top: 16px;
}
.botBox {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
}
.botBox div:nth-child(1) {
  width: 458px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  display: flex;
  align-items: center;
  margin-left: 24px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.botBox div:nth-child(1) span {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: rgba(9, 87, 195, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  font-size: 20px;
  font-weight: 400;
  color: #0957c3;
  text-align: center;
  line-height: 38px;
  margin-right: 8px;
}
.botBox div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-right: 24px;
}
</style>
