import utils from '../utils/api'
// 学段 分类
export function dictionary(params) {
  return utils({
    url: '/xznc/dictionary/list/parentId',
    method: "GET",
    params
  })
}
//
// 获取地区
export function regionList() {
  return utils({
    url: '/xznc/region/list/child/city',
    method: "GET",

  })
}
// 资讯列表
export function infoPage(data) {
  return utils({
    url: '/xznc/info/page',
    method: "POST",
    data
  })
}
// 资讯详情
export function infoPageDetail(params) {
  return utils({
    url: '/xznc/info/info/' + params.id,
    method: "GET",
  })
}
// 资讯评论、
export function commentPage(params) {
  return utils({
    url: '/xznc/comment/page',
    method: "GET",
    params
  })
}
// 新增评论
export function commentAdd(data) {
  return utils({
    url: '/xznc/comment/add',
    method: "POST",
    data
  })
}
// 名校列表
export function schoolPage(data) {
  return utils({
    url: '/xznc/info/page/school',
    method: "POST",
    data
  })
}
// 名校详情
export function schoolDetail(params) {
  return utils({
    url: 'xznc/school/info/' + params.id,
    method: "GET",
  })
}
// 我的消息
export function messagePage(params) {
  return utils({
    url: '/xznc/user/message/page',
    method: "GET",
    params
  })
}
// 消息单个删除
export function messageDel(data) {
  return utils({
    url: 'xznc/user/message/del/' + data.obj.id,
    method: "POST",
  })
}
// 消息详情
export function messageInfo(params) {
  return utils({
    url: '/xznc/user/message/info/' + params.id,
    method: "GET",
  })
}
// 上传头像
export function uploadHead(data) {
  return utils({
    url: '/xznc/user/upload/head',
    method: "POST",
    data
  })
}
// 消息一键清除
export function delBatch(data) {
  return utils({
    url: '/xznc/user/message/del/batch',
    method: "POST",
    data
  })
}
// 消息一键已读
export function messageRead(data) {
  return utils({
    url: '/xznc/user/message/read',
    method: "POST",
    data
  })
}
// 查询用户信息
export function userInfo(params) {
  return utils({
    url: '/xznc/user/info/' + params.id.id,
    method: "GET",
  })
}
// 编辑用户信息
export function userUpdata(data) {
  return utils({
    url: '/xznc/user/update',
    method: "POST",
    data
  })
}
// 偏好管理
export function preferenceUpdate(data) {
  return utils({
    url: '/xznc/user/preference/update',
    method: "POST",
    data
  })
}
// 轮播图
export function bannerList() {
  return utils({
    url: '/xznc/banner/list',
    method: "GET",
  })
}
// 评论点赞收藏
export function statAdd(data) {
  return utils({
    url: '/xznc/info/stat/add',
    method: "POST",
    data
  })
}

export function browseAdd(data) {
  return utils({
    url: '/xznc/info/browse/add',
    method: "POST",
    data
  })
}
// 举报
export function reportAdd(data) {
  return utils({
    url: '/xznc/comment/report/add',
    method: "POST",
    data
  })
}
// 查询是否点赞收藏
export function infoStat(params) {
  return utils({
    url: '/xznc/info/stat/info/stat',
    method: "GET",
    params
  })
}
// 取消点赞 收藏
export function statDel(data) {
  return utils({
    url: '/xznc/info/stat/del',
    method: "POST",
    data
  })
}
// 获取省市区数据
export function getRegionTree() {
  return utils({
    url: '/xznc/region/tree',
    method: "GET"
  })
}

export function getLastOneMessage() {
  return utils({
    url: "/xznc/user/message/last/one",
    method: "GET"
  })
}


